import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Doodle #1",
  "featuredImage": "images/Jesus.jpg",
  "coverAlt": "Doodle 1",
  "description": "Doodle! Definitely not random; let me know if you can read it.",
  "date": "2017-03-16",
  "dateModified": "2017-03-16",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Doodles", "Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Doodle! Definitely not random; let me know if you can read it.`}</p>
    <p><strong parentName="p">{`Hint`}</strong>{`: Sun shines Light. Water brings Life. We’re all Blood bought. Raindrops bring Growth. Concrete Foundation.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/92850eda5506b881054c950b5e58544a/5814a/Jesus.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHgq1MAAA//xAAZEAACAwEAAAAAAAAAAAAAAAABAhAREiD/2gAIAQEAAQUClTklr5//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAZEAABBQAAAAAAAAAAAAAAAAAAARARIEH/2gAIAQEABj8CeUMr/8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAgRAgIf/aAAgBAQABPyHJeBcICVBr/9oADAMBAAIAAwAAABD3333/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAaEAEBAAIDAAAAAAAAAAAAAAABEQAQICFB/9oACAEBAAE/ENjewCQURIkxCrPseP8A/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Doodle 1",
              "title": "Doodle 1",
              "src": "/static/92850eda5506b881054c950b5e58544a/5814a/Jesus.jpg",
              "srcSet": ["/static/92850eda5506b881054c950b5e58544a/543cd/Jesus.jpg 360w", "/static/92850eda5506b881054c950b5e58544a/20801/Jesus.jpg 720w", "/static/92850eda5506b881054c950b5e58544a/5814a/Jesus.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Doodle 1`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      